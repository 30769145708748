<template>
  <b-row class="text-start">
    <b-col cols="12">
      <b-button variant="primary" class="m-1" @click="generate('guessDrinkByRecipe')">Järgmine koostise järgi
      </b-button>
      <b-button variant="primary" class="m-1" @click="generate('guessContentByDrink')">Järgmine õige koostis</b-button>
      <b-button variant="primary" class="m-1" @click="generate('guessDescription')">Järgmine kokteil</b-button>
      <b-button variant="primary" class="m-1" @click="generate()">Järgmine suvaline</b-button>
    </b-col>
    <b-col>
      <b-card no-body class="overflow-hidden m-2" :class="selectedColor">
        <b-row v-if="currentType === 'guessDrinkByRecipe'">
          <b-col>
            <b-card-body v-if="variants">
              <b-card-text>
                <h4>Vali sobiv kokteil koostise järgi:</h4>
                <div v-for="i of processIngredients(correct.ingredients, ingredientFull)" :key="i">
                  <div> * {{ i }}</div>
                </div>
                <div class="my-2">
                  <b-button class="me-2 mt-2 text-start" variant="outline-primary" v-for="v of variants" :key="v.name"
                            @click="selected = v">
                    {{ v.name }}
                  </b-button>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row v-if="currentType === 'guessContentByDrink'">
          <b-col>
            <b-card-body v-if="variants">
              <b-card-text>
                <h4>{{ currentTitle }}</h4>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <div
                      v-for="i of variants"
                      :key="i"
                      class="me-2 mt-3">
                    <b-form-radio class="d-flex" v-model="selected" :value="i" :aria-describedby="ariaDescribedby">
                      <div class="ms-1">{{ i }}</div>
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row v-if="currentType === 'guessDescription'">
          <b-col>
            <b-card-body>
              <b-card-text>
                <h4>{{ currentTitle }}</h4>
                <b-textarea v-model="text" style="min-height: 100px"
                            placeholder="nt: all day, 3 cl viina, 7 cl viskit, segada, kokteiliklaas, pigista apelsinikoor, lisa münt jms">

                </b-textarea>
                <b-button class="my-3" variant="warning" @click="toggle = !toggle">Näita tegelikku</b-button>
                <b-card v-if="toggle">
                  <b-row>
                    <b-col md="3" class="p-md-0">
                      <b-card-img
                          :src="require(`@/assets/cocktails/${correct.code}.jpg`)"
                          :alt="`Image of ${correct.name}`"
                          width="12"
                          class="rounded-0"></b-card-img>
                    </b-col>
                    <b-col md="9" class="p-md-0">
                      <b-card-body>
                        <b-card-text>
                          <h5 class="m-0">{{ correct.name }} <small>{{
                              correct.alias ? `(${correct.alias.join(", ")})` : ""
                            }}</small></h5>
                          <div class="type-info mb-2">({{ correct.type.join(", ") }})</div>
                          <div v-for="i of processIngredients(correct.ingredients, ingredientFull)" :key="i">
                            <div> * {{ i }}</div>
                          </div>
                          <div class="mt-2">
                            {{ correct.recipeText }}
                          </div>
                          <div class="mt-2">
                            {{ correct.decorationText }}
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-col>

                  </b-row>
                </b-card>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import cocktails from "@/assets/cocktails.json"

export default {
  name: 'Test',
  data: function () {
    return {
      search: "",
      selected: null,
      currentTitle: null,
      quizTypes: ["guessDrinkByRecipe", "guessContentByDrink", "guessDescription"],
      checkboxes: [],
      currentType: null,
      variants: null,
      correct: null,
      toggle: false,
      text: false,
    }
  },
  computed: {
    selectedColor() {
      if (this.selected === null) return 'light'
      return this.selected === this.correct ? 'success' : 'danger'
    }
  },
  methods: {
    generate(func = null) {
      this.selected = null
      if (!func) {
        func = this.randomElement(this.quizTypes)
      }
      this.currentType = func
      this["generate_" + func]()
    },
    generate_guessDrinkByRecipe() {
      this.variants = this.randomCocktails(4);
      this.correct = this.variants[this.random(0, this.variants.length)]
      console.log(this.correct)
    },
    generate_guessContentByDrink() {
      let all = this.randomCocktails(4);
      let chosen = this.randomElement(all)
      this.variants = all.map(x => {
        return this.processIngredients(x.ingredients, this.ingredientName).filter(x => x).join(", ")
      })
      this.correct = this.processIngredients(chosen.ingredients, this.ingredientName).filter(x => x).join(", ")
      this.currentTitle = `Milline neist on '${chosen.name}' õige koostis?`
    },
    generate_guessDescription() {
      this.toggle = false;
      this.text = "";
      this.correct = this.randomCocktails(1)[0];
      this.currentTitle = `Kirjelda '${this.correct.name}', mis on tema õige koostis, kuidas teha, millega kaunistada?`
    },
    randomCocktails(amount, blacklist = []) {
      let output = []
      while (output.length < amount && amount < cocktails.length) {
        let random = this.randomElement(cocktails)
        if (!output.includes(random) && !blacklist.includes(random)) {
          output.push(random)
        }
      }
      return output
    },
    randomElement(arr) {
      return arr[this.random(0, arr.length)]
    },
    random(min, until) {
      return Math.floor(min + Math.random() * (until - min))
    },
    processIngredients(ings, method) {
      let out = []
      for (let i = 0; i < ings.length;) {
        let str = method(ings[i]);
        i++
        while (ings[i - 1].orNext) {
          str += " / " + method(ings[i])
          i++
        }
        out.push(str)
      }
      return out
    },
    ingredientFull(i) {
      let out = `${i.amount || ""} ${i.unit || ""} ${i.prefix || ""} ${i.name}`.trim()
      return i.optional ? `(${out})` : out
    },
    ingredientName(i) {
      if (i.optional) return null
      return i.name
    }
  },
  created() {
    this.generate()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.type-info {
  font-size: 0.8rem;
}

.card-text {
  font-size: .9rem;
}

input::placeholder {
  color: #bbb;
}

.success {
  background: #d4ffd6;
}

.danger {
  background: #ffd1d1;
}
</style>
